import React, { Component } from "react";
import Lottie from "react-lottie";
import rightTickMarkAnimJSON from "./rightTickMarkAnimJSON.json";

class RightTickMarkAnim extends Component {
  defaultOptions = {};

  constructor(props) {
    super(props);
    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: rightTickMarkAnimJSON,
    };
  }

  render() {
    return (
      <>
      <div className="rightClickMark">
        <Lottie options={this.defaultOptions} height={150} width={300} />
        </div>
      </>
    );
  }
}

export default RightTickMarkAnim;
