import React, { Component } from "react";
import Lottie from "react-lottie";
import cancelOrderJSON from "./cancelOrderJSON.json"

class CancelOrderAnim extends Component {
  defaultOptions = {};

  constructor(props) {
    super(props);
    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: cancelOrderJSON,
    };
  }

  render() {
    return (
      <>
        <Lottie 
          options={this.defaultOptions} />
      </>
    );
  }
}

export default CancelOrderAnim;
