export const paytmConfigure = ({
  orderId,
  amount,
  token,
  mid,
  merchantName,
  logo,
  callbackUrl,
}) => {
  return {
    style: {
      bodyBackgroundColor: "#fafafb",
      bodyColor: "",
      themeBackgroundColor: "#dfa231",
      themeColor: "#ffffff",
      headerBackgroundColor: "#284055",
      headerColor: "#ffffff",
      errorColor: "",
      successColor: "",
      card: {
        padding: "",
        backgroundColor: "",
      },
    },
    jsFile: "",
    data: {
      orderId: orderId,
      amount: amount,
      token: token,
      tokenType: "TXN_TOKEN",
      userDetail: {
        mobileNumber: "",
        name: "",
      },
      mid: mid,
    },
    merchant: {
      mid: mid,
      name: merchantName,
      logo: logo,
      redirect: true,
      callbackUrl: callbackUrl,
      // callbackUrl: `https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=${orderId}`,
    },
    mapClientMessage: {},
    labels: {},
    payMode: {
      labels: {},
      filter: {
        exclude: [],
      },
      order: ["NB", "CARD", "LOGIN"],
    },
    flow: "DEFAULT",
  };
};
