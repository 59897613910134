import React, { Component } from "react";
import Lottie from "react-lottie";
import { getValueFromLanguageObject } from "../../store";

import noProductFoundEditOrderJSON from "../json/noProductFoundEditOrderJSON.json";

class NoProductFoundEditOrderAnim extends Component {
  defaultOptions = {};

  constructor(props) {
    super(props);

    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: noProductFoundEditOrderJSON,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
  }

  render() {
    return (
      <>
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <div className="col-sm-12 empty-cart-cls text-center animationSize-300-300">
                    <Lottie
                      options={this.defaultOptions}
                    />
                    <h4>
                      <strong>
                        {getValueFromLanguageObject(
                          "str_explore_more_shortlist_some_items"
                        )
                          ? getValueFromLanguageObject(
                              "str_explore_more_shortlist_some_items"
                            )
                          : "Explore more shortlist some items."}
                      </strong>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default NoProductFoundEditOrderAnim;
