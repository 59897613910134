import { MASTER_DETAILS_ID } from "../constants/ActionTypes";
const initialState = {
  masterDetailsObj: {},
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case MASTER_DETAILS_ID:
      return { ...state, masterDetailsObj: action.masterDetailsObj };

    default:
      return state;
  }
};
export default languageReducer;
