import React, { Component } from "react";
import Lottie from "react-lottie";
import Modal from "react-responsive-modal";
import noProductFoundSpecialProductAnimJSON from "./noProductFoundSpecialProductAnimJSON.json";
import { getValueFromLanguageObject } from "../store";

class NoSpecialProductFoundAnim extends Component {
  defaultOptions = {};

  constructor(props) {
    super(props);
    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: noProductFoundSpecialProductAnimJSON,
    };
  }

  render() {
    return (
      <>
        <div className="col-12 d-flex justify-content-center">
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="animationSize-200-200">
                    <Lottie options={this.defaultOptions} />
                  </div>
                  <h3 className="mt-4">
                    <strong>
                      {getValueFromLanguageObject("str_no_products_found")
                        ? getValueFromLanguageObject("str_no_products_found")
                        : "No Products Found"}
                    </strong>
                  </h3>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default NoSpecialProductFoundAnim;
