import React, { Component } from "react";
import Lottie from "react-lottie";
import deleteJSON from "../json/delete.json"

class DeleteAnim extends Component {
  defaultOptions = {};

  constructor(props) {
    super(props);
    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: deleteJSON,
      
    };
  }

  render() {
    return (
      <>
        <Lottie
          options={this.defaultOptions} />
      </>
    );
  }
}

export default DeleteAnim;
